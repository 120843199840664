import * as PIXI from 'pixi.js';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ShareIcon from '@mui/icons-material/Share';
import * as styles from './utils/style.module.css';
import PPGraph from './classes/GraphClass';
import PPStorage from './PPStorage';
import InterfaceController from './InterfaceController';
import { IGraphSearch, TRgba } from './utils/interfaces';
import {
  getLoadGraphExampleURL,
  getLoadNodeExampleURL,
  removeExtension,
  useIsSmallScreen,
  writeTextToClipboard,
} from './utils/utils';
import { VISIBILITY_ACTION } from './utils/constants_shared';
import { getAllNodesFormattedForInterface } from './nodes/allNodes';
import MDXCreate from './help/help.mdx';
import MDXAbout from './help/about.mdx';
import { hri } from 'human-readable-ids';

type FilterContentProps = {
  readonly handleFilter: (
    event: React.MouseEvent<HTMLElement>,
    newFilter: string | null,
  ) => void;
  readonly filter: string;
};

function FilterContainer(props: FilterContentProps) {
  return (
    <ToggleButtonGroup
      value={props.filter}
      exclusive
      fullWidth
      onChange={props.handleFilter}
      aria-label="socket filter"
      size="small"
      sx={{
        bgcolor: 'background.paper',
        borderRadius: '0px',
      }}
    >
      <ToggleButton
        id="inspector-filter-graphs"
        value="graphs"
        aria-label="graphs"
      >
        My&nbsp;playgrounds
      </ToggleButton>
      <ToggleButton id="inspector-filter-help" value="help" aria-label="help">
        Help
      </ToggleButton>
      <ToggleButton
        id="inspector-filter-nodes"
        value="nodes"
        aria-label="nodes"
      >
        Nodes
      </ToggleButton>
      <ToggleButton
        id="inspector-filter-about"
        value="about"
        aria-label="about"
      >
        About
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(1),
  elevation: 0,
  borderRadius: 0,
  overflow: 'auto',
  userSelect: 'text',
  ul: {
    paddingLeft: '16px',
  },
  ol: {
    paddingLeft: '16px',
  },
  a: {
    color: theme.palette.secondary.light,
  },
}));

const LeftsideContent = (props) => {
  const [graphSearchItems, setGraphSearchItems] = useState<
    IGraphSearch[] | null
  >([{ id: '', name: '' }]);
  const [graphSearchActiveItem, setGraphSearchActiveItem] =
    useState<IGraphSearch | null>(null);

  const handleFilter = (
    event: React.MouseEvent<HTMLElement>,
    newFilter: string | null,
  ) => {
    props.setFilter(newFilter);
  };

  const updateGraphSearchItems = () => {
    load();

    async function load() {
      console.log('LOADING ALL GRAPHS FROM DATABASE');
      const remoteGraphs: any[] =
        await PPStorage.getInstance().getRemoteGraphsList();
      const remoteGraphSearchItems = remoteGraphs
        .filter((file) => file.endsWith('.ppgraph'))
        .map((graph) => {
          const name = removeExtension(graph);
          return {
            id: graph,
            name: name,
            label: 'remote',
            isRemote: true,
          } as IGraphSearch;
        });

      // add remote header entry
      if (remoteGraphSearchItems.length > 0) {
        remoteGraphSearchItems.unshift({
          id: `remote-header`,
          name: 'Remote playgrounds', // opening a remote playground creates a local copy
          isDisabled: true,
        });
      }

      const newGraphSearchItems: IGraphSearch[] =
        await PPStorage.getInstance().getGraphsList();

      // add local header entry
      if (newGraphSearchItems.length > 0) {
        newGraphSearchItems.unshift({
          id: `local-header`,
          name: 'Local playgrounds',
          isDisabled: true,
        });
      }

      const allGraphSearchItems = [
        ...newGraphSearchItems,
        ...remoteGraphSearchItems,
      ];
      setGraphSearchItems(allGraphSearchItems);

      if (PPGraph.currentGraph) {
        const selectedItem = newGraphSearchItems.find(
          (item) => item.id === PPGraph.currentGraph.id,
        );
        setGraphSearchActiveItem(selectedItem);
      }
    }
  };

  const loadGraph = (id, isRemote) => {
    if (isRemote) {
      PPStorage.getInstance().cloneRemoteGraph(id);
    } else {
      PPStorage.getInstance().loadGraphFromDB(id);
      const selectedItem = graphSearchItems.find((item) => item.id === id);
      setGraphSearchActiveItem(selectedItem);
    }
  };

  InterfaceController.onGraphListChanged = updateGraphSearchItems;
  useEffect(() => {
    updateGraphSearchItems();
  }, []);

  return (
    <Box
      sx={{
        color: TRgba.fromString(props.randomMainColor)
          .getContrastTextColor()
          .hex(),
        code: {
          bgcolor: `${TRgba.fromString(props.randomMainColor).darken(0.6)}`,
          padding: '2px 5px 2px',
          whiteSpace: 'nowrap',
          fontSize: '0.95em',
        },
        a: {
          textDecoration: 'none',
        },
        userSelect: 'none',
      }}
    >
      <FilterContainer handleFilter={handleFilter} filter={props.filter} />
      <Stack
        spacing={1}
        sx={{
          mt: 1,
          overflow: 'auto',
          height: 'calc(100vh - 100px)',
        }}
      >
        {(props.filter === 'graphs' || props.filter == null) && (
          <Item>
            <GraphsContent
              graphs={graphSearchItems}
              graphSearchActiveItem={graphSearchActiveItem}
              loadGraph={loadGraph}
              randomMainColor={props.randomMainColor}
            />
          </Item>
        )}
        {(props.filter === 'help' || props.filter == null) && (
          <Item>
            <MDXCreate />
          </Item>
        )}
        {(props.filter === 'nodes' || props.filter == null) && (
          <Item>
            <NodesContent randomMainColor={props.randomMainColor} />
          </Item>
        )}
        {(props.filter === 'about' || props.filter == null) && (
          <Item>
            <MDXAbout />
          </Item>
        )}
      </Stack>
    </Box>
  );
};

const GraphsContent = (props) => {
  return (
    <>
      <Box
        sx={{
          textAlign: 'right',
          mb: 1,
        }}
      >
        <Button
          variant="text"
          size="small"
          title="Share this playground"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            InterfaceController.setShowSharePlayground(true);
          }}
          sx={{
            mr: 1,
          }}
          endIcon={<ShareIcon />}
          data-cy="shareCurrentButton"
        >
          Share current
        </Button>
        <Button
          variant="contained"
          size="small"
          title="Create local playground"
          onClick={async (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            await PPGraph.currentGraph.clear();
            PPGraph.currentGraph.id = hri.random();
            PPStorage.getInstance().saveGraphAction();
          }}
          sx={{
            color: TRgba.fromString(props.randomMainColor)
              .getContrastTextColor()
              .hex(),
            boxShadow: 'none',
          }}
          endIcon={<AddIcon />}
        >
          Create new
        </Button>
      </Box>
      <List
        id="graphs-list"
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 160px)',
          paddingLeft: '0 !important',
        }}
        subheader={<li />}
      >
        {props.graphs.map((property) => {
          return (
            <GraphItem
              key={`item-${property.id}`}
              graphSearchActiveItem={props.graphSearchActiveItem}
              loadGraph={props.loadGraph}
              property={property}
              randomMainColor={props.randomMainColor}
              sx={{
                listStyleType: 'none',
                m: 1,
              }}
            />
          );
        })}
      </List>
    </>
  );
};

interface GraphItemProps {
  property: IGraphSearch;
  graphSearchActiveItem: IGraphSearch | null;
  randomMainColor: string;
  loadGraph: (id: string, isRemote: boolean) => void;
  sx: any;
}

const GraphItem = memo(
  ({
    property: graph,
    graphSearchActiveItem,
    randomMainColor,
    loadGraph,
  }: GraphItemProps) => {
    const smallScreen = useIsSmallScreen();

    // Memoize expensive calculations
    const url = useMemo(
      () => (graph.isRemote ? getLoadGraphExampleURL(graph.name) : null),
      [graph.isRemote, graph.name],
    );

    const contrastTextColor = useMemo(
      () => TRgba.fromString(randomMainColor).getContrastTextColor().hex(),
      [randomMainColor],
    );

    // Memoize event handlers
    const handleMainClick = useCallback(() => {
      loadGraph(graph.id, graph.isRemote);
      if (smallScreen) {
        InterfaceController.toggleLeftSideDrawer(VISIBILITY_ACTION.CLOSE);
      }
    }, [graph.id, graph.isRemote, loadGraph, smallScreen]);

    const handleCopyUrl = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation();
        InterfaceController.setIsGraphSearchOpen(false);
        if (url) writeTextToClipboard(url);
      },
      [url],
    );

    const handleOpenNewTab = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation();
        InterfaceController.setIsGraphSearchOpen(false);
        if (url) window.open(url, '_blank')?.focus();
      },
      [url],
    );

    const handleDownload = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation();
        InterfaceController.setIsGraphSearchOpen(false);
        PPStorage.getInstance().downloadGraph(graph.id);
      },
      [graph.id],
    );

    const handleEdit = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation();
        InterfaceController.setGraphToBeModified(graph);
        InterfaceController.setShowGraphEdit(true);
      },
      [graph],
    );

    const handleDelete = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation();
        InterfaceController.setGraphToBeModified(graph);
        InterfaceController.setShowGraphDelete(true);
      },
      [graph],
    );

    if (graph.isDisabled) {
      return (
        <ListSubheader
          sx={{
            lineHeight: '40px',
            paddingLeft: '8px',
            bgcolor: `${TRgba.fromString(randomMainColor).darken(0.7)}`,
          }}
        >
          {graph.name}
        </ListSubheader>
      );
    }

    return (
      <ListItem
        sx={{
          p: 0,
          '&:hover + .MuiListItemSecondaryAction-root': {
            visibility: 'visible',
          },
          bgcolor: `${TRgba.fromString(randomMainColor).darken(0.6)}`,
          margin: '1px 0',
        }}
        title={
          graph.isRemote
            ? `Load remote playground\nNOTE: Save it after loading, if you want to make changes to it`
            : `Load local playground\n${graph.id}`
        }
      >
        <ListItemButton
          selected={graph.id === graphSearchActiveItem?.id}
          onClick={handleMainClick}
          sx={{
            px: 1,
            py: 0,
            '&.Mui-selected': {
              bgcolor: `${TRgba.fromString(randomMainColor)}`,
              color: contrastTextColor,
            },
          }}
        >
          <ListItemText
            primary={graph.name}
            primaryTypographyProps={{
              sx: { fontStyle: graph.isRemote ? 'italic' : 'inherit' },
            }}
            secondary={graph.label}
            secondaryTypographyProps={{
              sx: {
                fontSize: '10px',
                '.Mui-selected &': {
                  color: contrastTextColor,
                },
              },
            }}
          />
        </ListItemButton>
        <ListItemSecondaryAction
          data-cy={`hover-${graph.name}`}
          sx={{
            visibility: 'hidden',
            '&&:hover': {
              visibility: 'visible',
            },
            '.MuiListItem-root:has(+ &:hover)': {
              background: 'rgba(255, 255, 255, 0.08)',
            },
            bgcolor: `${TRgba.fromString(randomMainColor).darken(0.6)}`,
            right: '8px',
          }}
        >
          {graph.isRemote ? (
            <>
              <IconButton
                size="small"
                onClick={handleCopyUrl}
                title="Copy URL"
                data-cy="copyURLButton"
                className={styles.menuItemButton}
              >
                <LinkIcon />
              </IconButton>
              <IconButton
                size="small"
                title="Open in new tab"
                data-cy="openInNewtabButton"
                className={styles.menuItemButton}
                onClick={handleOpenNewTab}
              >
                <OpenInNewIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                onClick={handleDownload}
                title="Download playground"
                data-cy="downloadButton"
                className={styles.menuItemButton}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={handleEdit}
                title="Rename playground"
                data-cy="editButton"
                className={styles.menuItemButton}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                title="Delete playground"
                data-cy="deleteButton"
                className={styles.menuItemButton}
                onClick={handleDelete}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison function for memo
    return (
      prevProps.property.id === nextProps.property.id &&
      prevProps.property.name === nextProps.property.name &&
      prevProps.property.isRemote === nextProps.property.isRemote &&
      prevProps.graphSearchActiveItem?.id ===
        nextProps.graphSearchActiveItem?.id &&
      prevProps.randomMainColor === nextProps.randomMainColor
    );
  },
);

const NodesContent = (props) => {
  return (
    <>
      <h3>Available nodes</h3>
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'hidden',
          paddingLeft: '0 !important',
        }}
        subheader={<li />}
      >
        {getAllNodesFormattedForInterface().map((property) => {
          return (
            <NodeItem
              property={property}
              randomMainColor={props.randomMainColor}
              sx={{
                listStyleType: 'none',
              }}
            />
          );
        })}
      </List>
    </>
  );
};

const NodeItem = memo(
  (props: any) => {
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 });

    const handleMouseDown = (event) => {
      setIsDragging(true);
      setDragStart({ x: event.clientX, y: event.clientY });
      document.body.style.cursor = 'copy';
    };

    const handleMouseMove = (event) => {
      if (!isDragging) return;
      setCurrentPosition({ x: event.clientX, y: event.clientY });
    };

    const handleMouseUp = (event) => {
      if (!isDragging) return;
      setIsDragging(false);
      document.body.style.cursor = 'default';
      PPGraph.currentGraph.overrideNodeCursorPosition =
        PPGraph.currentGraph.viewport.toWorld(
          new PIXI.Point(event.clientX, event.clientY),
        );
      PPGraph.currentGraph.addOrReplaceNode(event, props.property);
    };

    useEffect(() => {
      if (isDragging) {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
      } else {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      }

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }, [isDragging]);

    return (
      <ListItem
        key={`list-item-${props.property.title}`}
        sx={{
          p: 0,
          '&:hover + .MuiListItemSecondaryAction-root': {
            visibility: 'visible',
          },
          bgcolor: `${TRgba.fromString(props.randomMainColor).darken(0.6)}`,
          margin: '1px 0',
        }}
        title="Add node"
      >
        <ListItemButton
          sx={{
            p: 1,
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
          onMouseDown={handleMouseDown}
        >
          <Stack
            sx={{
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                title={props.property.description}
                sx={{
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'inline',
                  }}
                >
                  {props.property.name}
                </Box>
              </Box>
              <Box>
                {props.property.tags?.map((name) => (
                  <Box
                    key={`tag-${name}`}
                    sx={{
                      fontSize: '12px',
                      background: 'rgba(255,255,255,0.2)',
                      cornerRadius: '4px',
                      marginLeft: '2px',
                      px: 0.5,
                      display: 'inline',
                      '.Mui-focused &': {
                        display: 'none',
                      },
                    }}
                  >
                    {name}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: '12px',
                opacity: '0.75',
                textOverflow: 'ellipsis',
              }}
            >
              <Box
                sx={{
                  display: 'inline',
                }}
              >
                {props.property.description}
              </Box>
            </Box>
          </Stack>
        </ListItemButton>
        {!isDragging && props.property.hasExample && (
          <ListItemSecondaryAction
            sx={{
              visibility: 'hidden',
              '&&:hover': {
                visibility: 'visible',
              },
              '.MuiListItem-root:has(+ &:hover)': {
                background: 'rgba(255, 255, 255, 0.08)',
              },
              bgcolor: `${TRgba.fromString(props.randomMainColor).darken(0.6)}`,
              right: '8px',
            }}
          >
            <IconButton
              size="small"
              title="Open node example"
              className={styles.menuItemButton}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                window.open(
                  getLoadNodeExampleURL(props.property.title),
                  '_blank',
                );
              }}
              sx={{
                borderRadius: 0,
              }}
            >
              <Box
                sx={{
                  color: 'text.secondary',
                  fontSize: '10px',
                  px: 0.5,
                }}
              >
                Open example
              </Box>
              <OpenInNewIcon sx={{ fontSize: '16px' }} />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.property.name == nextProps.property.name;
  },
);

export default LeftsideContent;
