import React from 'react';
import { SocketParsingWarning } from '../../classes/ErrorClass';
import { TParseType, TRgba } from '../../utils/interfaces';
import { ArrayWidget } from '../../widgets';
import { AbstractType, Compatibility, DataTypeProps } from './abstractType';
import { ArrayType } from './arrayType';
import { AnyType } from './anyType';

export class JSONArrayType extends ArrayType {
  constructor() {
    super();
  }
  getName(): string {
    return 'JSON Array';
  }

  getInputWidget = (props: DataTypeProps): any => {
    props.dataType = this;
    return <ArrayWidget {...props} />;
  };

  getColor(): TRgba {
    return new TRgba(144, 103, 245);
  }

  getMetaText(data: any): string {
    return (
      '(' + (Array.isArray(data) ? data.length.toString() : 'Invalid') + ')'
    );
  }
  protected dataIsCompatible(
    data: any,
    convertFrom: AbstractType = new AnyType(),
  ): Compatibility {
    return AbstractType.warningsToCompatibility(this.parse(data).warnings);
  }

  parse(data: any): TParseType {
    const parsed = super.parse(data);
    if (parsed.value.length > 1 && typeof parsed.value[0] !== 'object') {
      parsed.warnings.push(
        new SocketParsingWarning('Array not of type object'),
      );
    }
    return parsed;
  }

  recommendedOutputNodeWidgets(): string[] {
    return [
      'MapExecuteMacro',
      'Filter',
      'ArrayLength',
      'ArraySlice',
      'ConcatenateArrays',
      'ArrayGet',
    ];
  }

  recommendedInputNodeWidgets(): string[] {
    return ['Constant', 'ColorArray', 'ArrayCreate'];
  }
}
