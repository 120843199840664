import PPNode from '../../classes/NodeClass';
import Socket from '../../classes/SocketClass';
import { SOCKET_TYPE } from '../../utils/constants';

export class DynamicInputNode extends PPNode {
  public getSocketForNewConnection = (socket: Socket): Socket =>
    DynamicInputNodeFunctions.getSocketForNewConnection(socket, this);

  public socketShouldAutomaticallyAdapt(socket: Socket): boolean {
    return true;
  }
}

// i structured it like this so that classes that cannot directly inherit from DynamicInputNode (because JS/TS doesn't allow multiple inheritance) can still use these
export class DynamicInputNodeFunctions {
  static getSocketForNewConnection(
    socket: Socket,
    node: PPNode,
    alwaysNewSocket = false,
  ): Socket {
    if (socket.isInput()) {
      return node.getSocketForNewConnection(socket);
    } else {
      const possibleConnection = node
        .getAllInterestingInputSockets()
        .find((socket) => socket.links.length == 0);
      if (possibleConnection !== undefined && !alwaysNewSocket) {
        return possibleConnection;
      } else {
        const newSocket = new Socket(
          SOCKET_TYPE.IN,
          node.getNewSocketName(socket.name),
          socket.dataType,
        );
        newSocket.existOnlyOnLink = true;
        node.addDynamicSocket(newSocket);
        node.resizeAndDraw();
        return newSocket;
      }
    }
  }
}
