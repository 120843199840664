/* eslint-disable @typescript-eslint/no-this-alias */
import PPNode from '../../classes/NodeClass';
import Socket from '../../classes/SocketClass';
import { PNPCustomStatus } from '../../classes/ErrorClass';
import { NODE_TYPE_COLOR, SOCKET_TYPE } from '../../utils/constants';
import { CustomArgs, TNodeSource, TRgba } from '../../utils/interfaces';
import {
  parseValueAndAttachWarnings,
  updateDataIfDefault,
} from '../../utils/utils';
import { AbstractType } from '../datatypes/abstractType';
import { AnyType } from '../datatypes/anyType';
import { ArrayType } from '../datatypes/arrayType';
import { StringType } from '../datatypes/stringType';
import { CodeType } from '../datatypes/codeType';
import { JSONType } from '../datatypes/jsonType';
import { NumberType } from '../datatypes/numberType';
import * as PIXI from 'pixi.js';
import { DynamicInputNode } from '../abstract/DynamicInputNode';
import InputArrayKeysType, {
  CONSTANT_NAME,
  ENTIRE_OBJECT_NAME,
  INDEX_NAME,
} from '../datatypes/inputArrayKeysType';
import { BooleanType } from '../datatypes/booleanType';
import { JSONArrayType } from '../datatypes/jsonArrayType';
import { inputReverseName } from '../draw/draw';
import PPGraph from '../../classes/GraphClass';
import { PNPWorker } from './worker/PNPWorker';
import { hri } from 'human-readable-ids';

const arrayName = 'Array';
const typeName = 'Type';
const arrayOutName = 'Out';

export const anyCodeName = 'Code';
export const allowFullAccessName = 'Main Thread';
const outDataName = 'OutData';

const constantInName = 'In';
const constantOutName = 'Out';

const input1Name = 'Input 1';
const input2Name = 'Input 2';

const indexName = 'Index';

const inputPropertyName = 'Property';
const inputInverseName = 'Reverse';

const inputStartName = 'Start';
const inputEndName = 'End';

const JSONName = 'JSON';

const selectedName = 'Element';
const lengthName = 'Length';

export class MergeJSONs extends PPNode {
  public getName(): string {
    return 'Merge JSONs';
  }

  public getDescription(): string {
    return 'Merges 2 JSON objects';
  }

  public getTags(): string[] {
    return ['JSON'].concat(super.getTags());
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, input1Name, new JSONType(), {}),
      new Socket(SOCKET_TYPE.IN, input2Name, new JSONType(), {}),
      new Socket(SOCKET_TYPE.OUT, constantOutName, new JSONType()),
    ];
  }
  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    outputObject[constantOutName] = {
      ...inputObject[input1Name],
      ...inputObject[input2Name],
    };
  }
}

export class ConcatenateArrays extends PPNode {
  public getName(): string {
    return 'Concatenate arrays';
  }

  public getDescription(): string {
    return 'Merges 2 arrays';
  }

  public getTags(): string[] {
    return ['Array'].concat(super.getTags());
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, input1Name, new ArrayType(), ['hello']),
      new Socket(SOCKET_TYPE.IN, input2Name, new ArrayType(), ['hello again']),
      new Socket(SOCKET_TYPE.OUT, constantOutName, new ArrayType()),
    ];
  }
  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    outputObject[constantOutName] = inputObject[input1Name].concat(
      inputObject[input2Name],
    );
  }
}

const constantDefaultData = 0;

export class Constant extends PPNode {
  initialData: any;

  constructor(name: string, customArgs?: CustomArgs) {
    super(name, {
      ...customArgs,
    });

    this.initialData = customArgs?.initialData;
  }

  public getName(): string {
    return 'Constant';
  }

  public getDescription(): string {
    return 'Provides a constant input';
  }

  public getTags(): string[] {
    return ['Input'].concat(super.getTags());
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.INPUT);
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        constantInName,
        new AnyType(),
        constantDefaultData,
      ),
      new Socket(SOCKET_TYPE.OUT, constantOutName, new AnyType()),
    ];
  }

  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    outputObject[constantOutName] = inputObject?.[constantInName];
  }

  public socketShouldAutomaticallyAdapt(socket: Socket): boolean {
    return true;
  }

  // this is copy pasted between several classes!!!
  public async populateDefaults(socket: Socket): Promise<void> {
    const dataToUpdate = socket.defaultData;
    await updateDataIfDefault(
      this,
      constantInName,
      constantDefaultData,
      dataToUpdate,
    );
    await super.populateDefaults(socket);
  }
}

export class ParseArray extends PPNode {
  public getName(): string {
    return 'Parse array';
  }

  public getDescription(): string {
    return 'Transforms all elements of an array to a different data type. Use it to, for example, to parse a number string "12" to a number';
  }

  public getTags(): string[] {
    return ['Array'].concat(super.getTags());
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, arrayName, new ArrayType(), []),
      new Socket(SOCKET_TYPE.IN, typeName, new NumberType(), 1),
      new Socket(SOCKET_TYPE.OUT, arrayOutName, new ArrayType()),
    ];
  }
  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    const inputArray = inputObject[arrayName];
    outputObject[arrayOutName] = inputArray.map((element) => {
      const socket = this.getSocketByName(typeName);
      const value = parseValueAndAttachWarnings(this, socket.dataType, element);
      return value;
    });
  }
}

export class ConsolePrint extends PPNode {
  public getName(): string {
    return 'Console print';
  }

  public getDescription(): string {
    return 'Logs the input in the console';
  }

  public getTags(): string[] {
    return ['Debug'].concat(super.getTags());
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.OUTPUT);
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        constantInName,
        new StringType(),
        'Hello from console',
      ),
    ];
  }

  protected async onExecute(inputObject: any): Promise<void> {
    console.log(inputObject[constantInName]);
  }
}

export function arrayEntryToSelectedValue(
  arrayEntry: any,
  selection: string,
  index = -1,
  constant = 0,
) {
  if (selection == ENTIRE_OBJECT_NAME) {
    return arrayEntry;
  } else if (selection == INDEX_NAME) {
    return index;
  } else if (selection == CONSTANT_NAME) {
    return constant;
  } else {
    return arrayEntry[selection];
  }
}

function getArgumentsFromFunction(inputFunction: string): string[] {
  const argumentsRegex = /(\(.*?\))/; // include everything in first parenthesis but nothing more
  const res = inputFunction.match(argumentsRegex)[0];
  const cleaned = res.replace('(', '').replace(')', '');
  let codeArguments = cleaned.split(',').filter((clean) => clean.length); // avoid empty string as parameter
  codeArguments = codeArguments
    .map((argument) => argument.trim())
    .filter((argument) => argument !== '');
  return [...new Set(codeArguments)];
}

function getFunctionFromFunction(inputFunction: string): string {
  const functionRegex = /({(.|\s)*})/;
  const res = inputFunction.match(functionRegex)[0];
  return res;
}

// customfunction does any number of inputs but only one output for simplicity
export class CustomFunction extends PPNode {
  modifiedBanner: PIXI.Graphics;
  previousUserInput = '';
  previousCodeToUse = '';

  public getName(): string {
    return 'Custom function';
  }

  public getDescription(): string {
    return 'Write your own custom function. Add input sockets, by adding parameters in the parentheses, separated by commas.';
  }

  public getTags(): string[] {
    return ['Custom'].concat(super.getTags());
  }

  public hasExample(): boolean {
    return true;
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        anyCodeName,
        new CodeType(),
        this.getDefaultFunction(),
        false,
      ),
      new Socket(
        SOCKET_TYPE.IN,
        allowFullAccessName,
        new BooleanType(),
        false,
        false,
      ),
      new Socket(
        SOCKET_TYPE.OUT,
        this.getOutputParameterName(),
        this.getOutputParameterType(),
      ),
      new Socket(
        SOCKET_TYPE.OUT,
        anyCodeName,
        new CodeType(),
        '',
        this.getOutputCodeVisibleByDefault(),
      ),
    ];
  }

  public isCallingMacro(macroName: string): boolean {
    return this.getInputData(anyCodeName)
      ?.replaceAll("'", '"') // this question mark is ugly... but it might be called before node gets the input data
      .includes('acro("' + macroName);
  }

  public static replaceMacroNameInCode(oldCode, oldName, newName): string {
    const replaceMacro = (code: string, quote: string) =>
      code.replace(
        new RegExp(`macro\\(${quote}${oldName}${quote}([^)]*)\\)`, 'g'),
        `macro(${quote}${newName}${quote}$1)`,
      );

    return replaceMacro(replaceMacro(oldCode, '"'), "'");
  }

  public calledMacroChangedName(oldName: string, newName: string): void {
    if (!this.getInputSocketByName(anyCodeName).links.length) {
      this.setInputData(
        anyCodeName,
        CustomFunction.replaceMacroNameInCode(
          this.getInputData(anyCodeName),
          oldName,
          newName,
        ),
      );
    }
  }

  protected getDefaultParameterValues(): Record<string, any> {
    return {};
  }

  protected getDefaultParameterTypes(): Record<string, AbstractType> {
    return {};
  }

  protected getOutputParameterType(): AbstractType {
    return new AnyType();
  }

  protected getOutputParameterName(): string {
    return outDataName;
  }

  protected getOutputCodeVisibleByDefault(): boolean {
    return false;
  }

  protected getDefaultFunction(): string {
    return '(a) => {\n\treturn a;\n}';
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.DEFAULT);
  }

  public async onNodeAdded(source: TNodeSource): Promise<void> {
    await super.onNodeAdded(source);
    this.modifiedBanner = this._StatusesRef.addChild(new PIXI.Graphics());
    // added this to make sure all sockets are in place before anything happens (caused visual issues on load before)
    if (this.getInputData(anyCodeName) !== undefined) {
      this.adaptInputs(this.getInputData(anyCodeName));
    }
  }

  protected replaceMacros(functionToExecute: string) {
    // we fix the macros for the user so that they are more pleasant to type
    const foundMacroCalls = [...functionToExecute.matchAll(/macro\(.*?\)/g)];

    return foundMacroCalls.reduce((formatted, macroCall) => {
      const macroContents = macroCall
        .toString()
        .replace('macro(', '')
        .replace(')', '');
      const parameters = macroContents.trim().split(',');

      let formattedParamsString = parameters[0];
      formattedParamsString += ',';
      formattedParamsString += '[';
      for (let i = 1; i < parameters.length; i++) {
        formattedParamsString += parameters[i] + ',';
      }
      formattedParamsString += ']';
      const finalMacroDefinition =
        'await CURRENT_GRAPH.invokeMacro(' + formattedParamsString + ')';

      return formatted.replace(macroCall.toString(), finalMacroDefinition);
    }, functionToExecute);
  }

  protected showModifiedBanner(): boolean {
    return true;
  }

  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    // avoid recalculating this function if input didnt change
    if (inputObject[anyCodeName] !== this.previousUserInput) {
      this.previousUserInput = inputObject[anyCodeName];
      // before every execute, re-evaluate inputs
      const changeFound = this.adaptInputs(inputObject[anyCodeName]);

      const sanitized = this.previousUserInput;
      const replacedMacros = this.replaceMacros(sanitized);

      this.previousCodeToUse = getFunctionFromFunction(replacedMacros);
      if (changeFound) {
        // there might be new inputs, so re-run rawexecute
        this.debug_timesExecuted--;
        return await this.rawExecute();
      }
    }
    const CURRENT_GRAPH = PPGraph.currentGraph; // used for macro call
    const paramKeys = Object.keys(inputObject).filter(
      (key) => key !== anyCodeName && key !== allowFullAccessName,
    );
    const defineAllVariablesFromInputObject = paramKeys
      .map(
        (argument) =>
          'const ' + argument + ' = inputObject["' + argument + '"];',
      )
      .join(';');
    const functionWithVariablesFromInputObject = this.previousCodeToUse.replace(
      '{',
      '{' + defineAllVariablesFromInputObject,
    );

    if (
      this.showModifiedBanner() &&
      this.getDefaultFunction() !== inputObject[anyCodeName]
    ) {
      this.pushExclusiveCustomStatus(
        new PNPCustomStatus('Modified', this.getColor().multiply(0.8)),
      );
    }

    let res = undefined;
    if (inputObject[allowFullAccessName]) {
      const finalized = 'async () => ' + functionWithVariablesFromInputObject;
      res = await (await eval(finalized))();
    } else {
      const finalized =
        'async (inputObject) => ' + functionWithVariablesFromInputObject;
      const worked = await new PNPWorker().work({
        code: finalized,
        data: inputObject,
      });
      if (!worked.success) {
        throw worked.error;
      }
      res = worked.result;
    }
    outputObject[this.getOutputParameterName()] = res;
    outputObject[anyCodeName] = this.previousUserInput;
  }

  // returns true if there was a change
  protected adaptInputs(code: string): boolean {
    const functionName = code
      .split('(')[0]
      .replaceAll('function', '')
      .replaceAll('const', '')
      .trim();
    if (
      functionName.length < 100 &&
      this.nodeName !== functionName &&
      functionName.length
    ) {
      console.log('updating custom function node name');
      this.setNodeName(functionName);
    }

    const codeArguments = getArgumentsFromFunction(code);
    // remove all non existing arguments and add all missing (based on the definition we just got)
    const currentInputSockets = this.getAllNonDefaultInputSockets();
    const socketsToBeRemoved = currentInputSockets.filter(
      (socket) => !codeArguments.some((argument) => socket.name === argument),
    );
    const argumentsToBeAdded = codeArguments.filter(
      (argument) =>
        !this.getAllInputSockets().some((socket) => socket.name === argument),
    );
    socketsToBeRemoved.forEach((socket) => {
      this.removeSocket(socket);
    });
    argumentsToBeAdded.forEach((argument) => {
      const type = this.getDefaultParameterTypes()[argument] || new AnyType();
      this.addInput(
        argument,
        type,
        this.getDefaultParameterValues()[argument] || type.getDefaultValue(),
        true,
        {},
        false,
      );
    });
    if (socketsToBeRemoved.length > 0 || argumentsToBeAdded.length > 0) {
      // sort sockets based on their location in code arguments
      this.inputSocketArray.sort((socket1, socket2) => {
        return (
          codeArguments.indexOf(socket1.name) -
          codeArguments.indexOf(socket2.name)
        );
      });
      this.metaInfoChanged();
      return true;
    }
    return false;
  }
  // adapt all nodes apart from the code one
  public socketShouldAutomaticallyAdapt(socket: Socket): boolean {
    return socket.name !== anyCodeName;
  }

  public getVersion(): number {
    return 3;
  }

  public async migrate(previousVersion: number): Promise<void> {
    if (previousVersion === 1 || previousVersion === 2) {
      // many older graphs are dependent on full access
      this.setInputData(allowFullAccessName, true);
    }
  }
}

class ArrayFunction extends CustomFunction {
  // the function that will be called inside the array function, for example what to filter on
  protected getInnerCode(): string {
    return '(a) => a';
  }

  protected getDefaultParameterValues(): Record<string, any> {
    return { ArrayIn: [], InnerCode: this.getInnerCode() };
  }
  protected getDefaultParameterTypes(): Record<string, AbstractType> {
    return {
      ArrayIn: new ArrayType(),
      InnerCode: new CodeType(),
      Index: new NumberType(true),
    };
  }
  protected getOutputParameterName(): string {
    return 'Out';
  }
  protected getOutputParameterType(): AbstractType {
    return new ArrayType();
  }
  public getTags(): string[] {
    return ['Array'].concat(super.getTags());
  }
}

function arrayNodeIO() {
  return [
    new Socket(SOCKET_TYPE.IN, arrayName, new ArrayType(), []),
    new Socket(SOCKET_TYPE.OUT, arrayName, new ArrayType(), []),
  ];
}

class ArrayFunctionCodeBasic extends PPNode {
  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        anyCodeName,
        new CodeType(),
        '(a, index) => a',
      ),
    ]
      .concat(arrayNodeIO())
      .concat(super.getDefaultIO());
  }

  public getTags(): string[] {
    return ['Array'].concat(super.getTags());
  }
}

export class MapNode extends ArrayFunctionCodeBasic {
  public getName(): string {
    return 'Map';
  }

  public getDescription(): string {
    return 'Transform each element of an array';
  }
  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    const res = await new PNPWorker().work({
      code:
        '(arrayIn) => {return arrayIn.map(' + inputObject[anyCodeName] + ')}',
      data: inputObject[arrayName],
    });
    if (!res.success) {
      throw res.error;
    }
    outputObject[arrayName] = res.result;
  }
}

export class Filter extends ArrayFunctionCodeBasic {
  public getName(): string {
    return 'Filter';
  }

  public getDescription(): string {
    return 'Filters an array using your own filter condition';
  }

  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    const res = await new PNPWorker().work({
      code:
        '(arrayIn) => {return arrayIn.filter(' +
        inputObject[anyCodeName] +
        ')}',
      data: inputObject[arrayName],
    });
    if (!res.success) {
      throw res.error;
    }
    outputObject[arrayName] = res.result;
  }
}

export class Uniques extends ArrayFunction {
  public getName(): string {
    return 'Array Uniques';
  }

  public getDescription(): string {
    return 'Returns an array with unique values, removing all duplicates';
  }
  protected getDefaultFunction(): string {
    return '(ArrayIn) => {\n\treturn [...new Set(ArrayIn)];\n}';
  }
}

export class Counts extends ArrayFunction {
  public getName(): string {
    return 'Array Counts';
  }

  public getDescription(): string {
    return 'Counts occurrences of elements in an array, by providing an array and an array with the unique values';
  }

  protected getDefaultFunction(): string {
    return `(ArrayIn) => {
        const results = {}
        ArrayIn.forEach(entry => {
          if (results[entry] == undefined){
            results[entry] = 0;
          }
          results[entry]++;
        });
        return results;
    }`;
  }
}

export class Flatten extends ArrayFunction {
  public getName(): string {
    return 'Array Flatten';
  }

  public getDescription(): string {
    return 'Flattens an array. All sub-array elements will be concatenated into it recursively';
  }

  protected getDefaultFunction(): string {
    return '(ArrayIn) => {\n\treturn ArrayIn.flat();\n}';
  }
}

export class ArraySlice extends PPNode {
  public getName(): string {
    return 'Slice';
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, inputStartName, new NumberType(true), 0),
      new Socket(SOCKET_TYPE.IN, inputEndName, new NumberType(true), 10),
    ]
      .concat(arrayNodeIO())
      .concat(super.getDefaultIO());
  }

  public getDescription(): string {
    return 'Slices an index using given start and end';
  }

  protected async onExecute(input, output): Promise<void> {
    output[arrayName] = input[arrayName].slice(
      input[inputStartName],
      input[inputEndName],
    );
  }
}

export class ArrayCreate extends DynamicInputNode {
  public getName(): string {
    return 'Array Create';
  }

  public getDescription(): string {
    return 'Creates an array from selected values';
  }

  public getTags(): string[] {
    return ['Array'].concat(super.getTags());
  }

  protected getDefaultIO(): Socket[] {
    return [new Socket(SOCKET_TYPE.OUT, arrayName, new ArrayType(), [])];
  }

  protected async onExecute(input, output): Promise<void> {
    output[arrayName] = this.getAllInterestingInputSockets().map(
      (socket) => socket.data,
    );
  }
}

function migrateFromCustomFunctionCommon(node: PPNode): void {
  node.removeSocket(node.getInputSocketByName('Code'));
  node.removeSocket(node.getOutputSocketByName('Code'));
  node.removeSocket(node.getInputSocketByName('Main Thread'));
}
export class ArrayGet extends PPNode {
  public getName(): string {
    return 'Array Get';
  }

  public getDescription(): string {
    return 'Returns an element based on its index position';
  }
  protected getDefaultIO() {
    return (
      this.shouldHaveIndexSocket()
        ? [new Socket(SOCKET_TYPE.IN, indexName, new NumberType())]
        : []
    ).concat([
      new Socket(SOCKET_TYPE.IN, arrayName, new ArrayType(), []),
      new Socket(SOCKET_TYPE.OUT, selectedName, new AnyType()),
    ]);
  }

  protected shouldHaveIndexSocket() {
    return true;
  }
  protected decideIndexToGet(input) {
    return input[indexName];
  }

  protected async onExecute(input, output): Promise<void> {
    output[selectedName] = input[arrayName][this.decideIndexToGet(input)];
  }

  public getTags(): string[] {
    return ['Array', 'Get', 'Element', 'Select'].concat(super.getTags());
  }

  public socketShouldAutomaticallyAdapt(socket: Socket): boolean {
    return socket.name === selectedName;
  }
  public getVersion(): number {
    return 4;
  }

  public async migrate(previousVersion: number): Promise<void> {
    await super.migrate(previousVersion);
    if (
      previousVersion < 4 &&
      this.getInputSocketByName('ArrayIn') !== undefined
    ) {
      migrateFromCustomFunctionCommon(this);
      await this.replaceSocketWithOtherSocket(
        this.getInputSocketByName('ArrayIn'),
        this.getInputSocketByName(arrayName),
      );
    }
  }
}

export class ArrayLength extends PPNode {
  public getName(): string {
    return 'Array Length';
  }

  public getVersion(): number {
    return 4;
  }

  public async migrate(previousVersion: number): Promise<void> {
    await super.migrate(previousVersion);
    if (previousVersion < 4) {
      migrateFromCustomFunctionCommon(this);
      await this.replaceSocketWithOtherSocket(
        this.getInputSocketByName('ArrayIn'),
        this.getInputSocketByName(arrayName),
      );
    }
  }

  public getDescription(): string {
    return 'Returns the length of an array';
  }
  protected getDefaultIO() {
    return [
      new Socket(SOCKET_TYPE.IN, arrayName, new ArrayType()),
      new Socket(SOCKET_TYPE.OUT, lengthName, new NumberType()),
    ];
  }
  protected async onExecute(input, output): Promise<void> {
    output[lengthName] = input[arrayName].length;
  }

  public getTags(): string[] {
    return ['Array', 'Length', 'Size'].concat(super.getTags());
  }
}

export class ArrayFirst extends ArrayGet {
  public getName(): string {
    return 'Array First';
  }
  public getDescription(): string {
    return 'Gets the first element (if any) in an array';
  }
  protected shouldHaveIndexSocket() {
    return false;
  }
  protected decideIndexToGet(input) {
    return 0;
  }

  public getTags(): string[] {
    return ['First'].concat(super.getTags());
  }
}

export class ArrayLast extends ArrayGet {
  public getName(): string {
    return 'Array Last';
  }
  public getDescription(): string {
    return 'Gets the last element (if any) in an array';
  }
  protected shouldHaveIndexSocket() {
    return false;
  }
  protected decideIndexToGet(input) {
    return input[arrayName].length - 1;
  }

  public getTags(): string[] {
    return ['Last'].concat(super.getTags());
  }
}

export class ArrayPush extends ArrayFunction {
  public getName(): string {
    return 'Array add Element';
  }
  public getDescription(): string {
    return 'Adds an element at the end of the array';
  }
  protected getDefaultFunction(): string {
    return '(ArrayIn, Element) => {\n\tArrayIn.push(Element);\nreturn ArrayIn;\n}';
  }
}

export class Max extends ArrayFunction {
  public getName(): string {
    return 'Max element in array';
  }

  public getDescription(): string {
    return 'Returns the largest number of the array';
  }

  protected getDefaultFunction(): string {
    return '(ArrayIn) => {\n\treturn Math.max(...ArrayIn);\n}';
  }

  protected getOutputParameterName(): string {
    return 'Max Element';
  }
}

export class Min extends ArrayFunction {
  public getName(): string {
    return 'Min element in array';
  }

  public getDescription(): string {
    return 'Returns the smallest number of the array';
  }

  protected getDefaultFunction(): string {
    return '(ArrayIn) => {\n\treturn Math.min(...ArrayIn);\n}';
  }
  protected getOutputParameterName(): string {
    return 'Min Element';
  }
}

export class ArrayToObject extends ArrayFunction {
  public getName(): string {
    return 'Array to Object';
  }

  public getDescription(): string {
    return 'Converts an array into an object using a specified property as key';
  }

  protected getDefaultParameterValues(): Record<string, any> {
    return { ArrayIn: [], KeyPropertyName: 'key' };
  }
  protected getDefaultParameterTypes(): Record<string, AbstractType> {
    return { ArrayIn: new ArrayType(), KeyPropertyName: new StringType() };
  }

  protected getDefaultFunction(): string {
    return '(ArrayIn, KeyPropertyName) => {\n  return ArrayIn.reduce((obj, item) => {\n    const { [KeyPropertyName]: key, ...rest } = item;\n    obj[key] = rest;\n    return obj;\n  }, {});\n}\n';
  }

  protected getOutputParameterName(): string {
    return 'Object';
  }
}

export class Reduce extends ArrayFunction {
  public getName(): string {
    return 'Array Reduce';
  }

  public getDescription(): string {
    return 'Reduce (or fold) an array into a single value';
  }

  protected getDefaultFunction(): string {
    return '(ArrayIn) => { \n\
      return ArrayIn.reduce((sum, a) => sum + a,0);\n\
    }';
  }
  protected getOutputParameterName(): string {
    return 'Reduced';
  }
  protected getOutputParameterType(): AbstractType {
    return new AnyType();
  }
}

export class Sort extends PPNode {
  public getName(): string {
    return 'Array Sort';
  }

  public getDescription(): string {
    return 'Sort an array based on specified key';
  }
  public getTags(): string[] {
    return ['Array'].concat(super.getTags());
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, arrayName, new JSONArrayType()),
      new Socket(
        SOCKET_TYPE.IN,
        inputPropertyName,
        new InputArrayKeysType(arrayName, this.id, false),
      ),
      new Socket(SOCKET_TYPE.IN, inputReverseName, new BooleanType()),
      new Socket(SOCKET_TYPE.OUT, arrayName, new ArrayType()),
    ].concat(super.getDefaultIO());
  }

  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    const inputArray = inputObject[arrayName];
    const property = inputObject[inputPropertyName];
    const reverse = inputObject[inputReverseName];
    const shallowCopy = inputArray.slice();
    const toReturn = shallowCopy.sort(
      (v1, v2) =>
        arrayEntryToSelectedValue(v1, property) -
        arrayEntryToSelectedValue(v2, property),
    );
    if (reverse) {
      toReturn.reverse();
    }
    outputObject[arrayName] = toReturn;
  }
}

export class Group extends PPNode {
  public getName(): string {
    return 'Array Group';
  }

  public getDescription(): string {
    return 'Groups an array based on specified key';
  }
  public getTags(): string[] {
    return ['JSON', 'Array'].concat(super.getTags());
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(SOCKET_TYPE.IN, arrayName, new JSONArrayType()),
      new Socket(
        SOCKET_TYPE.IN,
        inputPropertyName,
        new InputArrayKeysType(arrayName, this.id, false),
      ),
      new Socket(SOCKET_TYPE.OUT, arrayName, new ArrayType()),
    ].concat(super.getDefaultIO());
  }

  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    const grouped = {};
    const Array = inputObject[arrayName];
    const Property = inputObject[inputPropertyName];
    Array.forEach((entry, index) => {
      const selectedValue = arrayEntryToSelectedValue(entry, Property, index);
      if (grouped[selectedValue] == undefined) {
        grouped[selectedValue] = [];
      }
      grouped[selectedValue].push(entry);
    });

    outputObject[arrayName] = Object.keys(grouped).map((key) => ({
      Name: key,
      Entries: grouped[key],
      Length: grouped[key].length,
    }));
  }
}

export class Reverse extends ArrayFunction {
  public getName(): string {
    return 'Array Reverse';
  }

  public getDescription(): string {
    return 'Reverse an array';
  }

  protected getDefaultFunction(): string {
    return '(ArrayIn, InnerCode) => { \n\
      return ArrayIn.toReversed() \n\
    }';
  }
}
