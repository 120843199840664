import Socket from '../../classes/SocketClass';
import UpdateBehaviourClass from '../../classes/UpdateBehaviourClass';
import { PNPCustomStatus } from '../../classes/ErrorClass';
import { SOCKET_TYPE, NODE_TYPE_COLOR } from '../../utils/constants';
import { TRgba } from '../../utils/interfaces';
import { wrapDownloadLink } from '../../utils/utils';
import { JSONType } from '../datatypes/jsonType';
import { StringType } from '../datatypes/stringType';
import {
  HTTPNode,
  companionDefaultAddress,
  outputContentName,
  sendThroughCompanionAddress,
  urlInputName,
} from './http';
import { ArrayType } from '../datatypes/arrayType';

const claudePromptName = 'Prompt';
const claudeOptionsName = 'Options';
const claudeEnvironmentalVariableAuthKey = 'API Key Name';
const claudeModelName = 'Model';
const claudeBase64ImagesName = 'Images';

export class ClaudeNode extends HTTPNode {
  public getName(): string {
    return 'Claude - Companion';
  }

  public getDescription(): string {
    return 'Claude (Anthropic) communication through the Plug and Play Companion, uses environmental variable for API key. Optionally supports base64 image input.';
  }

  public getAdditionalDescription(): string {
    return `<p>${wrapDownloadLink(
      'https://github.com/magnificus/pnp-companion-2/releases/',
      'Download Plug and Play Companion',
    )}</p>`;
  }

  public getUpdateBehaviour(): UpdateBehaviourClass {
    return new UpdateBehaviourClass(false, false, false, 1000, this);
  }

  protected getDefaultIO(): Socket[] {
    return [
      new Socket(
        SOCKET_TYPE.IN,
        urlInputName,
        new StringType(),
        'https://api.anthropic.com/v1/messages',
      ),
      new Socket(
        SOCKET_TYPE.IN,
        claudePromptName,
        new StringType(),
        'Give me a quick rundown of the battle of Hastings',
      ),
      new Socket(
        SOCKET_TYPE.IN,
        claudeModelName,
        new StringType(),
        'claude-3-5-sonnet-latest',
      ),
      new Socket(SOCKET_TYPE.IN, claudeOptionsName, new JSONType(), {
        max_tokens: 4096,
        temperature: 0.7,
        top_p: 1,
      }),
      new Socket(
        SOCKET_TYPE.IN,
        claudeEnvironmentalVariableAuthKey,
        new StringType(),
        'ANTHROPIC_API_KEY',
      ),
      new Socket(
        SOCKET_TYPE.IN,
        sendThroughCompanionAddress,
        new StringType(),
        companionDefaultAddress,
      ),
      new Socket(SOCKET_TYPE.IN, claudeBase64ImagesName, new ArrayType()),
      new Socket(SOCKET_TYPE.OUT, outputContentName, new JSONType(), {}),
    ];
  }

  private getMediaTypeFromDataUrl(dataUrl: string): string {
    const match = dataUrl.match(/^data:([^;]+);/);
    return match ? match[1] : 'image/jpeg'; // default to jpeg if no match
  }

  private formatMessageContent(prompt: string, base64Images: string[]): any[] {
    const content: any[] = [];

    // Add text if it exists
    if (prompt) {
      content.push({
        type: 'text',
        text: prompt,
      });
    }

    base64Images.forEach((image) => {
      const mediaType = this.getMediaTypeFromDataUrl(image);
      content.push({
        type: 'image',
        source: {
          type: 'base64',
          media_type: mediaType,
          data: image.replace(/^data:image\/[a-z]+;base64,/, ''), // Remove data URL prefix if present
        },
      });
    });

    return content;
  }

  protected async onExecute(
    inputObject: any,
    outputObject: Record<string, unknown>,
  ): Promise<void> {
    let returnResponse = {};
    this.pushExclusiveCustomStatus(
      new PNPCustomStatus('Companion', TRgba.white().multiply(0.5)),
    );

    try {
      const finalOptions = JSON.parse(
        JSON.stringify(inputObject[claudeOptionsName]),
      );

      const base64Images = inputObject[claudeBase64ImagesName] || [];
      const prompt = inputObject[claudePromptName];

      // Format the message according to Claude's API requirements
      const messageBody = {
        model: inputObject[claudeModelName],
        max_tokens: finalOptions.max_tokens,
        temperature: finalOptions.temperature,
        messages: [
          {
            role: 'user',
            content: this.formatMessageContent(prompt, base64Images),
          },
        ],
      };

      const companionSpecific = {
        finalHeaders: {
          'Content-Type': 'application/json',
          'Anthropic-Version': '2023-06-01',
          'x-api-key':
            '${' + inputObject[claudeEnvironmentalVariableAuthKey] + '}',
        },
        finalBody: JSON.stringify(messageBody),
        finalURL: inputObject[urlInputName],
        finalMethod: 'Post',
      };

      const res = await fetch(inputObject[sendThroughCompanionAddress], {
        method: 'Post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(companionSpecific),
      });

      const companionRes = await res.json();

      try {
        this.pushStatusCode(companionRes.status);
        returnResponse = JSON.parse(companionRes.response);
      } catch (error) {
        returnResponse = companionRes.response;
      }
    } catch (error) {
      console.log(error.stack);
      throw 'Unable to reach companion, is it running at designated address?';
    }

    outputObject[outputContentName] = returnResponse;
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.INPUT);
  }
}
