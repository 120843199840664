import React, { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import MonacoEditor, { monaco } from 'react-monaco-editor';
import { Box, Button } from '@mui/material';
import ErrorFallback from './ErrorFallback';
import { MAX_STRING_LENGTH } from '../utils/constants';
import { convertToString, getLoadedValue } from '../utils/utils';

type CodeEditorProps = {
  value: unknown;
  onChange?: (code: string) => void;
  randomMainColor: string;
  editable?: boolean;
  inDashboard?: boolean;
};

export const CodeEditor: React.FunctionComponent<CodeEditorProps> = ({
  value,
  onChange,
  randomMainColor,
  editable,
  inDashboard = false,
}) => {
  const minHeight = 48;
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();
  const boxRef = useRef<HTMLDivElement>(null);
  const resizeRef = useRef<HTMLDivElement>(null);
  const shouldLoadAll = (newValue) => {
    if (editorRef.current?.hasTextFocus()) {
      return true;
    }
    return convertToString(newValue)?.length < MAX_STRING_LENGTH;
  };

  const [loadAll, setLoadAll] = useState(shouldLoadAll(value));
  const [loadedValue, setLoadedValue] = useState(
    getLoadedValue(convertToString(value), loadAll),
  );
  const [editorHeight, setEditorHeight] = useState(minHeight);

  const onLoadAll = () => {
    setLoadedValue(convertToString(value));
    setLoadAll(true);
  };

  const changeEditorHeight = (max = Number.MAX_VALUE) => {
    const newContentHeight = editorRef.current.getContentHeight();
    if (editorHeight !== newContentHeight) {
      setEditorHeight(Math.min(Math.max(minHeight, newContentHeight), max));
    }
  };

  const editorDidMount = (editor) => {
    editorRef.current = editor;
    editorRef.current.addAction({
      id: 'Copy-lines-down',
      label: 'Copy lines down',
      keybindings: [
        monaco.KeyMod.Shift | monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyD,
      ],
      run: function () {
        editor.trigger('Copy lines down', 'editor.action.copyLinesDownAction');
      },
    });
    // when loading, max the editor heights earlier
    changeEditorHeight(Math.floor(window.innerHeight * 0.25));
  };

  const handleOnChange = (newValue, e) => {
    // when typing, automatically increase and allow for a larger max height
    changeEditorHeight(Math.floor(window.innerHeight * 0.6));
    setLoadedValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    const resizeHandle = resizeRef.current;
    const box = boxRef.current;

    const handleMouseDown = (e) => {
      e.preventDefault();
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
      const newHeight = e.clientY - box.getBoundingClientRect().top;
      setEditorHeight(Math.max(minHeight, newHeight));
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    if (resizeHandle) {
      resizeHandle.addEventListener('mousedown', handleMouseDown);
    }

    return () => {
      if (resizeHandle) {
        resizeHandle.removeEventListener('mousedown', handleMouseDown);
      }
    };
  }, []);

  useEffect(() => {
    const load = shouldLoadAll(value);
    setLoadAll(load);
    setLoadedValue(getLoadedValue(convertToString(value), load));
  }, [value]);

  useEffect(() => {
    changeEditorHeight(Math.floor(window.innerHeight * 0.25));
  }, [loadedValue]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Box
        ref={boxRef}
        sx={{
          position: 'relative',
          height: inDashboard ? '100%' : `${editorHeight}px`,
          minHeight: `${minHeight}px`,
          maxHeight: inDashboard ? 'unset' : '80vh',
          resize: inDashboard ? 'unset' : 'vertical',
          overflow: 'hidden',
        }}
      >
        {!loadAll && (
          <Button
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              zIndex: 10,
            }}
            color="secondary"
            variant="contained"
            size="small"
            onClick={onLoadAll}
          >
            Load all{editable && <span>(to edit)</span>}
          </Button>
        )}
        <MonacoEditor
          width="100%"
          height="100%"
          language="javascript"
          theme="vs-dark"
          value={loadedValue}
          options={{
            automaticLayout: true,
            lineNumbersMinChars: 4,
            minimap: { enabled: !loadAll },
            readOnly: !loadAll || !editable,
            scrollbar: {
              alwaysConsumeMouseWheel: false,
            },
            scrollBeyondLastLine: false,
            selectOnLineNumbers: true,
            tabSize: 2,
            wordWrap: 'on',
          }}
          onChange={handleOnChange}
          editorDidMount={editorDidMount}
        />
        {!inDashboard && (
          <div
            ref={resizeRef}
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: '100%',
              height: '10px',
              cursor: 'ns-resize',
            }}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};
